@import './../../../assets/constants/colors';

.copyrights {
  margin: 10px 0;
}

.footer {
  margin-top: 35px;
  @media screen and (max-width: 1199px) {
    .grid-item {
      display: block;
      text-align: center;
      margin: 10px 0;

      .footer-link {
        display: block;
        margin: 10px 0;
      }
    }
  }

  .grid-item.logo {
    .math-logo-svg {
      fill: $color-blue;
    }
  }
}

.footer {
  nav {
    padding: 0;
  }
  .footer-link {
    padding: 0 15px;
    font-size: 1rem;
    color: $color-blue;

    transition: color 0.5s ease;

    &:hover {
      color: $color-light-blue;
    }
  }
  .copyrights {
    text-align: center;
    color: $color-light-blue;
  }
}

.platform-style {
  .footer {
    padding-bottom: 10px;
    .footer-link {
      color: $color-white;

      &:hover {
        color: $color-yellow;
      }
    }

    .copyrights {
      color: $color-yellow;
    }
  }
}

.grid-item.social {
  a {
    color: $color-blue;
    &:hover {
      color: $color-light-blue;
    }
  }
}

@media screen and (min-width: 1200px) {
  .grid-container.footer {
    display: grid;
    grid-template-columns: 12% auto 12%;
    grid-template-rows: auto;
    grid-template-areas: "logo footer-links social";
    justify-content: stretch;

    .grid-item.logo{
      grid-area: logo;
      justify-self: start;
    }

    .grid-item.footer-links{
      grid-area: footer-links;
      justify-self: center;
    }

    .grid-item.social {
      grid-area: social;
      justify-self: end;
    }
  }

  .footer {
    nav {
      padding: 0 0 10px 0;
    }
  }
}