$s10: 0.625rem;
$s11: 0.6875rem;
$s12: 0.75rem;
$s13: 0.8125rem;
$s14: 0.875rem;
$s15: 0.9375rem;
$s16: 1rem;
$s17: 1.0625rem;
$s18: 1.125rem;
$s19: 1.1875rem;
$s20: 1.25rem;
$s21: 1.3125rem;
$s22: 1.375rem;
$s23: 1.4375rem;
$s24: 1.5rem;
$s25: 1.5625rem;
$s26: 1.625rem;
$s27: 1.6875rem;
$s28: 1.75rem;
$s29: 1.8125rem;
$s30: 1.875rem;
$s31: 1.9375rem;
$s32: 2rem;
$s33: 2.0625rem;
$s34: 2.125rem;
$s35: 2.1875rem;
$s36: 2.25rem;
$s37: 2.3125rem;
$s38: 2.375rem;
$s39: 2.4375rem;
$s40: 2.5rem;
$s41: 2.5625rem;
$s42: 2.625rem;
$s43: 2.6875rem;
$s44: 2.75rem;
$s45: 2.8125rem;
$s46: 2.875rem;
$s47: 2.9375rem;
$s48: 3rem;
$s49: 3.0625rem;
$s50: 3.125rem;
$s51: 3.1875rem;
$s52: 3.25rem;
$s53: 3.3125rem;
$s54: 3.375rem;
$s55: 3.4375rem;
$s56: 3.5rem;
$s57: 3.5625rem;
$s58: 3.625rem;
$s59: 3.6875rem;
$s60: 3.75rem;
$s61: 3.8125rem;
$s62: 3.875rem;
$s63: 3.9375rem;
$s64: 4rem;
$s65: 4.0625rem;
$s66: 4.5rem;
$s67: 4.5625rem;
$s68: 4.625rem;
$s69: 4.6875rem;
$s70: 4.75rem;
$s71: 4.8125rem;
$s72: 4.875rem;
$s73: 4.9375rem;
$s74: 5rem;
$s75: 5.0625rem;
$s76: 5.5rem;
$s77: 5.5625rem;
$s78: 5.625rem;
$s79: 5.6875rem;
$s80: 5.75rem;
$s81: 5.8125rem;
$s82: 5.875rem;
$s83: 5.9375rem;
$s84: 6rem;
$s85: 6.0625rem;
$s86: 6.5rem;
$s87: 6.5625rem;
$s88: 6.625rem;
$s89: 6.6875rem;
$s90: 6.75rem;
$s91: 6.8125rem;
$s92: 6.875rem;
$s93: 6.9375rem;
$s94: 7rem;
$s95: 7.0625rem;
$s96: 7.5rem;
$s97: 7.5625rem;
$s98: 7.625rem;
$s99: 7.6875rem;
$s100: 7.75rem;