
@import '../../../../assets/constants/colors';
@import '../../../../assets/constants/convert_px.scss';

$back-to-top-padding: 2px 10px;
$back-to-top-right: 30px;
$back-to-top-bottom: 30px;
$back-to-top-zIndex: 100;
$fadeIn-duration: 1s;
$fadeOut-duration: .5s;

.back-to-top {
	position: fixed;
	visibility: hidden;

	opacity: 0;
  transition: visibility 0s $fadeOut-duration, opacity $fadeOut-duration linear;

	right: $back-to-top-right;
	bottom: $back-to-top-bottom;

	padding: $back-to-top-padding;
	background-color: $color-yellow;

	z-index: 10;

	&.active {
		visibility: visible;
	}
}
.back-to-top-btn {
	color: $color-blue;
	font-size: $s24;
	.fa-angle-up {
		margin: 0;
	}
}

.fade-in {
  opacity: 1;
  transition: opacity $fadeIn-duration linear;
}