@import "./assets/constants/colors.scss";

.App {
  text-align: center;

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  position: relative;
  width: 100%;
  padding: 50px 0 0 0;
}

@media (min-width: 1200px) {
  #root {
    padding: 70px 0 0 0;
  }

  .platform-style #root {
    padding: 0;
  }
}

.wrapper {
  position: relative;
  max-width: 100%;
  margin: 0;

  &.blue-background {
    background: $color-blue;
    color: $color-white;
  }

  &.light-blue-background {
    background: $color-light-blue;
    color: $color-white;
  }

  &.yellow-background {
    background: $color-yellow;
    color: $color-blue;
  }

  &.white-background {
    background: $color-white;
    color: $color-blue;

    a,
    a:hover {
      color: $color-blue;

      &.easy-math-button.btn-blue {
        color: $color-white;
      }
    }

  }

  &.with-padding-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.tile-background {
    background-image: url("./assets/img/tile.svg");
    background-repeat: repeat;
    background-color: $color-light-blue;

    &.title-only {
      .heading-title {
        font-size: 1.8rem;
        color: #fff;
        text-transform: uppercase;
        text-shadow: 4px 4px 0 $color-blue;
        font-weight: 700;
        padding: 20px 0;
        text-align: center;

        @media screen and (min-width: 1200px) {
          text-align: left;
          font-size: 3rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .wrapper {
    padding: 0 6%;
    &.with-padding-mobile {
      padding-left: 6%;
      padding-right: 6%;
    }
  }
}