@mixin ellipsis($width, $whiteSpace) {
	max-width: $width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: $whiteSpace;
}

/*
Usage:

p {
    @include ellipsis;
}

*/