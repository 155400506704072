@import '../../assets/constants/colors';
@import '../../assets/constants/convert_px.scss';
@import '../../assets/mixins/ellipsis';

$addSubscription-shell-padding: 30px 90px;
$addSubscription-shell-padding-tablet: 30px 60px;
$addSubscription-shell-padding-mobile: 20px;

$subscription-plus-bottom-offset: 10px;

$subscription-invoice-field-columns: .6fr 1fr;
$subscription-invoice-field-columns-mobile: 1fr;
$subscription-invoice-subField-columns: 2fr .5fr;
$subscription-invoice-subField-gap: 10px;
$subscription-invoice-subField-position-mobile: 1;
$subscription-invoice-subField-margin-bottom: 44px;

$subscription-payment-columns: 1fr 1fr 1fr;
$subscription-payment-columns-mobile: 1fr;
$subscription-payment-heading-size: $s18;
$subscription-payment-heading-marginTop: 35px;

$subscription-price-marginBottom: 20px;
$subscription-price-size: $s20;
$subscription-amount-size: $s24;
$subscription-discount-size: $s30;
$subscription-amount-margin: 0 5px;

$choice-of-invoice-columns: 1fr 1fr;
$choice-of-invoice-gap: 20px;

$choice-of-invoice-columns-mobile: 1fr;
$choice-of-invoice-gap-mobile: 0;
$choice-invoice-offset: 44px;
$invoice-title-size: $s20;

$invoice-content-marginTop: 35px;

$invoice-legal-columns: 1fr;
$invoice-legal-item-gap: 30px;
$invoice-legal-item-gap-mobile: 0;

$invoice-legal-first-columns: 1fr .5fr .5fr;
$invoice-legal-second-columns: 1.57fr .5fr;
$invoice-legal-last-columns: 1fr 1fr;

$subscription-contest-first-columns: 2fr .8fr;
$subscription-contest-second-columns: 1fr .5fr;
$subscription-contest-last-columns: 1fr 1fr;

$invoice-legal-first-columns-mobile: 1fr;
$invoice-legal-second-columns-mobile: 1fr;
$invoice-legal-last-columns-mobile: 1fr;

$invoice-individual-first-columns-mobile: 1fr;
$invoice-individual-last-columns-mobile: 1fr;

$invoice-individual-first-columns: .5fr .5fr;
$invoice-individual-last-columns: 1.5fr .5fr;

$subscription-annotation-padding: 10px;
$subscription-annotation-maxHeight: 320px;

$subscription-payment-detail-size: $s20;
$selected-subscription-size: $subscription-payment-detail-size;
$selected-subscription-size-tablet: $s18;
$selected-subscription-size-mobile: $selected-subscription-size-tablet;
$selected-subscription-margin: 15px 0;
$subscription-payment-title-size: $s16;
$subscription-payment-marginTop: 10px;

$subscription-sum-icon-size: $s22;
$subscription-success-payment-margin: 30px auto;
$subscription-success-payment-maxWidth: 480px;
$subscription-success-payment-padding: 30px;

$success-payment-content-size: $s20;
$success-payment-content-size-tablet: $s18;
$success-payment-content-size-mobile: $success-payment-content-size-tablet;
$success-payment-content-margin: 5px 0;
$subscription-payment-img-maxHeight: 85px;
$success-payment-subscription-btn-marginTop: 30px;

$label-tooltip-maxWidth: 250px;
$label-tooltip-right: -15px;
$label-tooltip-size: $s16;
$label-tooltip-fa-question-size: $s18;
$label-tooltip-top: -10px;
$label-tooltip-top-mobile: -8px;
$label-tooltip-left-mobile: 220px;
$label-tooltip-right-mobile: auto;

$paymentSubscription-shell-padding: 40px;
$paymentSubscription-shell-padding-tablet: 50px 20px 20px;
$paymentSubscription-shell-padding-mobile: $paymentSubscription-shell-padding-tablet;
$payment-number-margin: 10px 0;
$payment-number-firstChild-marginTop: 20px;

$subscriptions-contests-marginBottom: 70px;

$subscriptions-contests-marginBottom: 70px;

.modal-shell {
  &.addSubscription-shell {
    padding: $addSubscription-shell-padding;
    background-color: $color-white;
  }
}
.subscription-title,
.subscription-info {
  line-height: 1.3;

  text-align: center;
  color: $color-blue;
}
.subscription-title {
  font-size: $s24;
}
.subscription-info {
  font-size: $s16;
}
.subscription-annotation {
  color: $color-blue;

  overflow-y: scroll;
  overflow-x: hidden;

  max-height: $subscription-annotation-maxHeight;

  padding: $subscription-annotation-padding;
  border: solid 1px $color-blue;
}
.subscription-plus {
  text-align: center;
  margin-bottom: $subscription-plus-bottom-offset;
  .fa-plus {
    margin: 0;

    font-size: $s86;
    color: $color-yellow;
  }
}
.add-subscription-form {
  position: relative;
}
.subscription-invoice-field {
  display: grid;
  grid-template-columns: $subscription-invoice-field-columns;
  .subscription-label {
    padding-left: 0;
  }
}
.subscription-invoice-subField {
  display: grid;
  grid-template-columns: $subscription-invoice-subField-columns;
  grid-column-gap: $subscription-invoice-subField-gap;
}
.subscription-payment-field {
  display: grid;
  grid-template-columns: $subscription-payment-columns;

  .form-field-set:first-of-type {
    text-align: center;
  }
  .form-field-set:last-of-type {
    text-align: right;
  }
}
.subscription-field-wrapper {
  position: relative;
  display: inline-block;
  .form-checked-tick {
    right: 0;
    left: auto;
  }
}
.subscription-label {
  width: auto;
}
.subscription-input {
  color: $color-blue;
  border-bottom: 1px solid $color-blue;
}
.subscription-payment-heading {
  margin-top: $subscription-payment-heading-marginTop;

  color: $color-blue;
  font-size: $subscription-payment-heading-size;
  font-weight: 500;
}
.subscription-price {
  margin-bottom: $subscription-price-marginBottom;

  font-size: $subscription-price-size;
  color: $color-blue;
  font-weight: 600;
}
.subscription-amount,
.subscription-discount {
  margin: $subscription-amount-margin;
}
.subscription-amount {
  font-size: $subscription-amount-size;
}
.subscription-discount {
  font-size: $subscription-discount-size;
  font-weight: 600;
  color: $color-red;
}
.choice-of-invoice {
  display: grid;
  grid-template-columns: $choice-of-invoice-columns;
  grid-column-gap: $choice-of-invoice-gap;
  .form-field-set:first-of-type {
    text-align: right;
  }
}
.choice-invoice-offset {
  margin-bottom: $choice-invoice-offset;
}
.invoice-content {
  margin-top: $invoice-content-marginTop;
}
.invoice-title {
  text-align: center;

  color: $color-blue;
  font-size: $invoice-title-size;
  font-weight: 600;
}
.invoice-legal,
.subscriptions-contests {
  display: grid;
  grid-template-columns: $invoice-legal-columns;
}
.invoice-legal-item,
.subscription-contest-item {
  display: grid;
  grid-column-gap: $invoice-legal-item-gap;
}
.invoice-legal-first {
  grid-template-columns: $invoice-legal-first-columns;
}
.invoice-legal-second {
  grid-template-columns: $invoice-legal-second-columns;
}
.invoice-legal-last {
  grid-template-columns: $invoice-legal-last-columns;
}
.invoice-individual-first {
  grid-template-columns: $invoice-individual-first-columns;
}
.invoice-individual-last {
grid-template-columns: $invoice-individual-last-columns;
}
.subscription-contest-first {
  grid-template-columns: $subscription-contest-first-columns;
}
.subscription-contest-second {
  grid-template-columns: $subscription-contest-second-columns;
}
.subscription-contest-last {
  grid-template-columns: $subscription-contest-last-columns;
}
/* Messages Subscription Success Payment */
.subscription-success-payment {
  max-width: $subscription-success-payment-maxWidth;

  margin: $subscription-success-payment-margin;
  padding: $subscription-success-payment-padding;

  border: solid 1px $color-blue;
}
.selected-subscription {
  margin: $selected-subscription-margin;
  font-size: $selected-subscription-size;
}
.subscription-payment-detail {
  font-size: $subscription-payment-detail-size;
  margin: 5px 0;

  &:last-of-type {
    text-decoration: underline;
  }
}
.subscription-payment-title {
  margin-top: $subscription-payment-marginTop;
  font-size: $subscription-payment-title-size;
  font-weight: 500;
}
.subscription-description {
  font-size: 0;
}
.subscription-month,
.subscription-sum {
  display: inline-block;
  margin: 0 2%;
  font-size: $s18;
}
.subscription-month {
  .fa-calendar-alt {
    font-size: $subscription-sum-icon-size;
  }
}
.subscription-sum {
  .fa-coins {
    font-size: $subscription-sum-icon-size;
  }
}
.success-payment-content {
  margin: $success-payment-content-margin;
  font-size: $success-payment-content-size;
}
.payment-number {
  font-weight: 600;
  margin: $payment-number-margin;

}
.offset-payment {
  margin-top: $payment-number-firstChild-marginTop;
}
.subscription-payment-img {
  display: block;

  max-height: $subscription-payment-img-maxHeight;
  margin: 0 auto;
}
.label-tooltip {
  position: absolute;

  top: $label-tooltip-top;
  right: $label-tooltip-right;

  padding: 0;
  font-size: $label-tooltip-size;
  opacity: .5;

  .fa-question-circle {
    margin: 0;
    font-size: $label-tooltip-fa-question-size;
  }

  &:hover {
    opacity: 1;
  }
}
.subscription-tooltip {
  max-width: $label-tooltip-maxWidth;
  text-align: left;
}

.modal-shell {
  &.paymentSubscription-shell {
    padding: $paymentSubscription-shell-padding;
    background-color: $color-white;
  }
}

/*
 * Subscription Competitions
 */

.subscriptions-contests {
  margin-bottom: $subscriptions-contests-marginBottom;
}

@media (min-width: 319px) and (max-width: 1199px) {
  .modal-shell {
    &.addSubscription-shell {
      padding: $addSubscription-shell-padding-tablet;
    }
  }
  .success-payment-content {
    font-size: $success-payment-content-size-tablet;
  }
  .selected-subscription {
    font-size: $selected-subscription-size-tablet;
  }
}
.payment-iban {
  word-break: break-all;
}
.success-payment-subscription {
  .no-size {
    margin-top: $success-payment-subscription-btn-marginTop;
  }
}
.modal-shell {
  &.paymentSubscription-shell {
    padding: $paymentSubscription-shell-padding-tablet;
  }
}
@media screen and (max-width: 840px) {
  .modal-shell {
    &.addSubscription-shell {
      padding: $addSubscription-shell-padding-mobile;
    }
  }
  .subscription-invoice-field {
    grid-template-columns: $subscription-invoice-field-columns-mobile;
  }
  .subscription-invoice-subField {
    grid-row: $subscription-invoice-subField-position-mobile;
    margin-bottom: $subscription-invoice-subField-margin-bottom;
  }
  .subscription-payment-field {
    grid-template-columns: $subscription-payment-columns-mobile;
    .form-field-set:first-of-type {
      text-align: left;
    }
    .form-field-set:last-of-type {
      margin-top: 0;
      text-align: left;
    }
  }
  .choice-of-invoice {
    grid-template-columns: $choice-of-invoice-columns-mobile;
    grid-column-gap: $choice-of-invoice-gap-mobile;
    .form-field-set:first-of-type {
      text-align: left;
    }
  }
  .invoice-legal-item {
    grid-column-gap: $invoice-legal-item-gap-mobile;
  }
  .invoice-legal-first {
    grid-template-columns: $invoice-legal-first-columns-mobile;
  }
  .invoice-legal-second {
    grid-template-columns: $invoice-legal-second-columns-mobile;
  }
  .invoice-legal-last {
    grid-template-columns: $invoice-legal-last-columns-mobile;
  }
  .invoice-individual-first {
    grid-template-columns: $invoice-individual-first-columns-mobile;
  }
  .invoice-individual-last {
    grid-template-columns: $invoice-individual-last-columns-mobile;
  }
  .success-payment-content {
    font-size: $success-payment-content-size-mobile;
  }
  .selected-subscription {
    font-size: $selected-subscription-size-mobile;
  }
  .label-tooltip {
    top: $label-tooltip-top-mobile;
    left: $label-tooltip-left-mobile;
    right: $label-tooltip-right-mobile;
  }
  .modal-shell {
    &.paymentSubscription-shell {
      padding: $paymentSubscription-shell-padding-mobile;
    }
  }
  .subscription-contest-first,
  .subscription-contest-second,
  .subscription-contest-last {
    grid-template-columns: auto;
  }
}