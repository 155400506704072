@import '../constants/colors';
@import '../constants/convert_px.scss';

$easy-math-button-padding: 0 48px;
$easy-math-button-padding-mobile: 0 28px;
$easy-math-button-size: $s18;
$easy-math-button-size-mobile: $s16;
$easy-math-button-minWidth: 230px;

$easyMath-platform-button-size: $easy-math-button-size;
$easyMath-platform-button-size-mobile: $easy-math-button-size-mobile;
$easyMath-platform-button-height: 50px;
$easyMath-platform-button-marginRight: 10px;
$easyMath-platform-button-padding: 0 48px;
$easyMath-platform-button-padding-mobile: 0 28px;
$main-platform-size-maxWidth: 310px;
$main-platform-size-maxWidth-mobile: 100%;
$main-platform-size-width: 100%;

$quiz-btn-size-maxWidth: 100%;
$quiz-btn-size-maxWidth-mobile: 100%;
$quiz-btn-size-width: 100%;

$btn-form-maxWidth: 230px;
$btn-form-width: 100%;
$easy-math-button-height: 50px;

$btn-account-maxWidth: $btn-form-maxWidth;
$btn-account-size: $s16;
$btn-account-padding: 0 10px;

$subscription-width: 100%;
$subscription-height: 27px;
$subscription-size-padding: 0 20px;

$main-btn-size-maxWidth: 310px;
$main-btn-size-width: 100%;
$main-btn-size-maxWidth-mobile: 100%;

$multiply-btn-size-maxWidth: 310px;
$multiply-btn-size-width: 100%;
$multiply-btn-size-maxWidth-mobile: 100%;

$multiply-btn-video-maxWidth: 310px;
$multiply-btn-video-width: 100%;
$multiply-btn-video-maxWidth-mobile: 100%;
$multiply-btn-video-padding: 0 20px;
$multiply-btn-video-padding-mobile: 0 10px;
$multiply-btn-video-margin: 20px 50px 0;
$multiply-btn-video-margin-tablet: 20px 10px 0;

$modal-btn-margin: 20px 10px 0;
$modal-btn-margin-mobile: 20px 0 0;

$exercise-btn-arrow-width: 100%;
$exercise-btn-arrow-maxWidth: 50px;
$exercise-btn-arrow-height: $exercise-btn-arrow-maxWidth;
$exercise-btn-arrow-padding: 0;

$exercise-btn-answer-margin: 0 20px;
$exercise-btn-answer-margin-mobile: 0 10px;
$exercise-btn-answer-padding: 0 20px;
$exercise-btn-answer-padding-mobile: 0 10px;
$exercise-btn-answer-maxWidth: 310px;
$exercise-btn-answer-width: 100%;

$hover-video-buttons-holder-grid-template: 1fr;
$hover-video-buttons-holder-top: 50%;

$hover-state-button-maxWidth: 180px;
$hover-state-button-padding: 10px 0;
$hover-state-button-padding-tablet: 8px 0;
$hover-state-button-padding-mobile: 5px 0;

$center-text-flex-mobile: 0 15px;

.easy-math-button {
  display: inline-block;

  height: $easy-math-button-height;
  padding: $easy-math-button-padding;

  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;

  font-size: $easy-math-button-size;

  &.no-size {
    max-width: auto;
    min-width: auto;
    width: auto;
  }
  &.no-padding {
    padding: 0;
  }
  &.no-margin {
    margin: 0;
  }
  &.center-context {
    line-height: 2.8;
  }
  &.center-text-flex {
    display: flex;
    align-items: center;

    height: auto;
  }
  &.bold-context {
    font-weight: 600;
  }
  &.btn-form-size {
    max-width: $btn-form-maxWidth;
    width: $btn-form-width;
  }
  &.btn-account-size {
    max-width: $btn-account-maxWidth;
    width: $btn-form-width;

    padding: $btn-account-padding;
    font-size: $btn-account-size;
    .fas {
      margin-right: 10px;
    }
  }
  &.subscription-size {
    width: $subscription-width;
    height: $subscription-height;
  }
  &.multiply-btn-size {
    max-width: $multiply-btn-size-maxWidth;
    width: $multiply-btn-size-width;
    margin: $modal-btn-margin;
    &.selected {
      background-color: $color-yellow;
      color: $color-blue;
    }
  }
  &.multiply-btn-video {
    max-width: $multiply-btn-video-maxWidth;
    width: $multiply-btn-video-width;
    margin: $multiply-btn-video-margin;
    padding: $multiply-btn-video-padding;
  }
  &.main-btn-size {
    max-width: $main-btn-size-maxWidth;
    width: $main-btn-size-width;
    text-align: center;
  }
  &.quiz-btn-size {
    max-width: $quiz-btn-size-maxWidth;
    width: $quiz-btn-size-width;
    min-height: 50px;
    text-align: center;
    height: auto;
    padding: 14px 10px;
    line-height: 1.2;

    &.selected {
      background-color: $color-blue;
      color: $color-yellow;
    }
  }
  &.exercise-btn-arrow {
    max-width: $exercise-btn-arrow-maxWidth;
    width: $exercise-btn-arrow-width;
    height: $exercise-btn-arrow-height;
    padding: $exercise-btn-arrow-padding;
    .fas {
      margin: 0;
      line-height: 50px;
    }
  }
  &.exercise-btn-answer {
    max-width: $exercise-btn-answer-maxWidth;
    width: $exercise-btn-answer-width;
    height: $exercise-btn-arrow-height;
    margin: $exercise-btn-answer-margin;
    padding: $exercise-btn-answer-padding;
  }
  &.btn-blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-yellow;
      color: $color-blue;
    }
  }

  &.btn-white {
    background-color: $color-white;
    color: $color-blue;

    &:hover {
      background-color: $color-yellow;
      color: $color-blue;
    }
  }

  &.btn-yellow {
    background-color: $color-yellow;
    color: $color-blue;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }

  &.btn-light-blue {
    background-color: $color-light-blue;
    color: $color-white;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }

  &.btn-text-blue {
    color: $color-blue;
  }

  &.btn-text-white {
    color: $color-white;
  }

  &.btn-text-yellow {
    color: $color-yellow;
  }

  &:disabled {
    cursor: not-allowed;

    &.btn-blue {
      background-color: $form-btn-blue-bg;
    }
    &.btn-white {
      background-color: $form-btn-white-bg;
    }
    &.btn-yellow {
      background-color: $form-btn-yellow-bg;
    }
    &.btn-text-blue {
      color: $form-btn-blue-bg;
    }
  }
  .fas {
    margin: 0 10px 0 0;
    font-size: $s28;
    vertical-align: middle;
  }
}

.easyMath-platform-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: $easyMath-platform-button-height;
  padding: $easyMath-platform-button-padding;

  font-size: $easyMath-platform-button-size;

  &.main-btn-size {
    max-width: $main-platform-size-maxWidth;
    width: $main-platform-size-width;
  }
  &.multiply-btn-size {
    max-width: $multiply-btn-size-maxWidth;
    width: $multiply-btn-size-width;
    margin: $modal-btn-margin;
  }
  &.btn-blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-yellow;
      color: $color-blue;
    }
  }
  .platform-svg {
    margin-right: $easyMath-platform-button-marginRight;
    fill: $color-white;

    -webkit-transition: fill 0.25s ease-in-out;
    -moz-transition: fill 0.25s ease-in-out;
    -ms-transition: fill 0.25s ease-in-out;
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    .platform-svg {
      fill: $color-blue;
    }
  }
}
.multiply-btn-wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hover-video-buttons-holder {
  position: absolute;

  display: grid;
  grid-template-columns: $hover-video-buttons-holder-grid-template;

  align-items: center;
  justify-content: center;

  top: $hover-video-buttons-holder-top;
  left: 0;
  right: 0;

  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  text-align: center;
  transform: translate(0, -70%);
}

.button-video-item {
  display: flex;

  align-items: center;
  justify-content: center;
}

.hover-state-button {
  display: inline-block;

  max-width: $hover-state-button-maxWidth;
  width: 100%;

  padding: $hover-state-button-padding;
  margin: 0 5px 10px;

  background: $color-blue;
  color: $color-white;

  text-align: center;
  font-size: $s16;
  font-weight: 700;

  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: $color-light-blue;
  }
  &.main-size {

  }
}

@media (min-width: 841px) and (max-width: 1199px) {
  .easy-math-button {
    &.multiply-btn-video {
      margin: $multiply-btn-video-margin-tablet;
    }
  }
  .hover-video-buttons-holder {
    transform: translate(0, -77%);
  }
  .hover-state-button {
    padding: $hover-state-button-padding-tablet;
  }
}

@media screen and (max-width: 840px) {
  .multiply-btn-wrapper {
    flex-direction: column;
  }
  .easy-math-button {
    padding: $easy-math-button-padding-mobile;
    font-size: $easy-math-button-size-mobile;
    &.center-context {
      line-height: 3;
    }
    &.center-text-flex {
      padding: $center-text-flex-mobile;
    }
    &.multiply-btn-size {
      max-width: $multiply-btn-size-maxWidth-mobile;
      margin: $modal-btn-margin-mobile;
    }
    &.multiply-btn-video {
      max-width: $multiply-btn-video-maxWidth-mobile;
      margin: $modal-btn-margin-mobile;
      padding: $multiply-btn-video-padding-mobile;
    }
    &.main-btn-size {
      max-width: $main-btn-size-maxWidth-mobile;
    }

    &.subscription-size {
      padding: $subscription-size-padding;
    }
    &.exercise-btn-answer {
      width: 100%;
      max-width: 100%;

      margin: $exercise-btn-answer-margin-mobile;
      padding: $exercise-btn-answer-padding-mobile;
    }
    &.quiz-btn-size {
      max-width: $quiz-btn-size-maxWidth-mobile;
    }
  }
  .easyMath-platform-button {
    padding: $easyMath-platform-button-padding-mobile;
    font-size: $easyMath-platform-button-size-mobile;
    &.main-btn-size {
      max-width: $main-platform-size-maxWidth-mobile;
    }
    &.multiply-btn-size {
      max-width: $multiply-btn-size-maxWidth-mobile;
      margin: $modal-btn-margin-mobile;
    }
  }
  .hover-state-button {
    font-size: $s14;
    padding: $hover-state-button-padding-mobile;

  }
}
@media screen and (max-width: 420px) {
  .easy-math-button {
    &.exercise-btn-answer {
      font-size: $s14;
      .fas {
        display: block;
        margin: 0;

        font-size: $s14;
      }
    }
  }
}