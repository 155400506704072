@import '../../../../assets/constants/colors';

$spinner-size: 1.2rem;
$spinner-logout-size: .4rem;

.spinner-wrapper {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  &.spinner-white {
    background-color: $spinner-white-background;
    .spinner {
      -webkit-animation: load5alt 1.1s infinite ease;
      animation: load5alt 1.1s infinite ease;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  &.spinner-blue {
    background-color: $spinner-blue-background;
    .spinner {
      -webkit-animation: load5 1.1s infinite ease;
      animation: load5 1.1s infinite ease;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  &.spinner-logout {
    .spinner {
      font-size: $spinner-logout-size;
    }
  }

  z-index: 4;
}

.spinner {
  position: absolute;

  top: 50%;
  left: 50%;

  font-size: $spinner-size;
  width: 1em;
  height: 1em;

  border-radius: 50%;
  text-indent: -9999em;
}

@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFC530, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, .7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, .7), 1.8em -1.8em 0 0em #FFC530, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, .7), 2.5em 0em 0 0em #FFC530, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, .7), 1.75em 1.75em 0 0em #FFC530, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, .7), 0em 2.5em 0 0em #FFC530, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, .7), -1.8em 1.8em 0 0em #FFC530, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, .7), -2.6em 0em 0 0em #FFC530, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, .7), -1.8em -1.8em 0 0em #FFC530;
  }
}

@keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFC530, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, .7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, .7), 1.8em -1.8em 0 0em #FFC530, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, .7), 2.5em 0em 0 0em #FFC530, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, .7), 1.75em 1.75em 0 0em #FFC530, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, .7), 0em 2.5em 0 0em #FFC530, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, .7), -1.8em 1.8em 0 0em #FFC530, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, .7), -2.6em 0em 0 0em #FFC530, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, .7), -1.8em -1.8em 0 0em #FFC530;
  }
}

@-webkit-keyframes load5alt {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFC530, 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, .7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, .7), 1.8em -1.8em 0 0em #FFC530, 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.5), 1.8em -1.8em 0 0em rgba(5, 81, 135, .7), 2.5em 0em 0 0em #FFC530, 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.5), 2.5em 0em 0 0em rgba(5, 81, 135, .7), 1.75em 1.75em 0 0em #FFC530, 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.5), 1.75em 1.75em 0 0em rgba(5, 81, 135, .7), 0em 2.5em 0 0em #FFC530, -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.5), 0em 2.5em 0 0em rgba(5, 81, 135, .7), -1.8em 1.8em 0 0em #FFC530, -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.5), -1.8em 1.8em 0 0em rgba(5, 81, 135, .7), -2.6em 0em 0 0em #FFC530, -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.5), -2.6em 0em 0 0em rgba(5, 81, 135, .7), -1.8em -1.8em 0 0em #FFC530;
  }
}

@keyframes load5alt {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFC530, 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.5), -1.8em -1.8em 0 0em rgba(5, 81, 135, .7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, .7), 1.8em -1.8em 0 0em #FFC530, 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.5), 1.8em -1.8em 0 0em rgba(5, 81, 135, .7), 2.5em 0em 0 0em #FFC530, 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.5), 2.5em 0em 0 0em rgba(5, 81, 135, .7), 1.75em 1.75em 0 0em #FFC530, 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.5), 1.75em 1.75em 0 0em rgba(5, 81, 135, .7), 0em 2.5em 0 0em #FFC530, -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.2), -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.5), 0em 2.5em 0 0em rgba(5, 81, 135, .7), -1.8em 1.8em 0 0em #FFC530, -2.6em 0em 0 0em rgba(5, 81, 135, 0.2), -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.5), -1.8em 1.8em 0 0em rgba(5, 81, 135, .7), -2.6em 0em 0 0em #FFC530, -1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(5, 81, 135, 0.2), 1.8em -1.8em 0 0em rgba(5, 81, 135, 0.2), 2.5em 0em 0 0em rgba(5, 81, 135, 0.2), 1.75em 1.75em 0 0em rgba(5, 81, 135, 0.2), 0em 2.5em 0 0em rgba(5, 81, 135, 0.2), -1.8em 1.8em 0 0em rgba(5, 81, 135, 0.5), -2.6em 0em 0 0em rgba(5, 81, 135, .7), -1.8em -1.8em 0 0em #FFC530;
  }
}