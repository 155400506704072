$global-black-reset: rgba(0, 0, 0, 0);
$auth-overlay-background: rgba(5, 81, 135, .6);
$auth-overlay-video-platform-background: rgba(0, 0, 0, .6);
$auth-overlay-background-mobile: rgb(101, 147, 179);

$form-btn-white-bg: rgba(255, 255, 255, .5);
$form-btn-blue-bg: rgba(5, 81, 135, .5);
$form-btn-yellow-bg: rgba(255, 197, 48, .5);

$color-blue-rgb: rgb(5, 81, 135);
$color-focus-blue: #216798;
$color-green: #00DB4C;
$color-black: #000;
$color-light-blue: #00B9F1;
$color-blue: #055187;
$color-white: #fff;
$color-yellow: #FFC530;
$color-red: #FF3636;

$color-white-placeholder: rgba(255, 255, 255, .6);
$color-blue-placeholder: $auth-overlay-background;
$form-input-errorColor: $color-red;

$spinner-white-background:rgba(255, 255, 255, 0.75);
$spinner-blue-background:rgba(5, 81, 135, 0.75);
$account-details-panel-color: #f3f3f3;