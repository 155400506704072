@import '../../../../assets/constants/colors';
@import '../../../../assets/mixins/ellipsis';
@import '../../../../assets/constants/convert_px.scss';

$modal-overlay-zIndex: 100;

$modal-wrapper-maxWidth-mobile: 100%;
$modal-wrapper-maxWith: 980px;
$modal-wrapper-width: 100%;

$modal-wrapper-padding-tablet: 20px;
$modal-wrapper-padding-mobile: $modal-wrapper-padding-tablet;

$modal-close-btn-size: $s24;
$modal-close-btn-top: 10px;
$modal-close-btn-right: 20px;
$modal-close-btn-right-mobile: 20px;

$fadeIn-duration: .5s;
$fadeOut-duration: .5s;

$modal-bought-subscription-width: 100%;
$modal-bought-subscription-padding: 13px 10px;

$modal-authBought-notify-size: $s18;
$modal-bought-grid-template: .4fr 4fr;
$modal-bought-grid-template-mobile: 1fr;
$modal-bought-subscription-icon-size: $s40;
$modal-authBought-notify-paddingLeft: 10px;
$modal-authBought-notify-padding-mobile: 10px;

$modal-svg-height: 155px;
$modal-title-size: $s24;
$modal-title-size-mobile: $s22;
$modal-title-margin: 20px 0;

$modal-error-offset-height: 50%;
$modal-error-offset-minWidth: 320px;
$modal-error-offset-height-mobile: 100%;
$modal-error-offset-minHeight: 450px;
$modal-success-offset-height: 700px;
$modal-success-offset-height-mobile: 100%;

/* Special case only for Video platform! */
.platform-style {
 .main-modal {
   &.overlay {
    background-color: $auth-overlay-video-platform-background;
   }
 }
}

.main-modal {
  display: none;
  opacity: 0;

  animation: fade $fadeOut-duration;
 -webkit-animation: fade $fadeOut-duration;
 -moz-animation: fade $fadeOut-duration;
 -ms-animation: fade $fadeOut-duration;

  &.overlay {
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $auth-overlay-background;
    z-index: $modal-overlay-zIndex;

    overflow-y: scroll;
    overflow-x: hidden;

    height: 100%;
    width: 100%;
  }
  &.active {
    display: flex;

    align-items: center;
    justify-content: center;

    opacity: 1;
  }
}

.modal-wrapper {
  max-width: $modal-wrapper-maxWith;
  width: $modal-wrapper-width;

  margin: auto;
}

.modal-close-btn {
  position: absolute;

  top: $modal-close-btn-top;
  right: $modal-close-btn-right;

  font-size: $modal-close-btn-size;
  z-index: 2;
}

.modal-shell {
  position: relative;
  width: 100%;
}

/*
 * Modal bought subscription and register Notify
 */
.modal-bought-subscription {
  display: grid;
  grid-template-columns: $modal-bought-grid-template;

  width: $modal-bought-subscription-width;
  padding: $modal-bought-subscription-padding;
  background-color: $color-yellow;

  color: $color-blue;
  text-align: center;
}

.modal-authBought-notify {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: $modal-authBought-notify-paddingLeft;
  line-height: 1.25;

  font-size: $modal-authBought-notify-size;
}

.modal-bought-subscription-icon {
  display: grid;
  align-items: center;

  .fa-exclamation-triangle {
    margin: 0;
    font-size: $modal-bought-subscription-icon-size;
  }
}

.modal-svg {
  height: $modal-svg-height;
}
.modal-title {
  font-size: $modal-title-size;
  margin: $modal-title-margin;
}
.modal-text-content {
  &.modal-text-blue{
    color: $color-blue;
  }
  &.modal-text-yellow{
    color: $color-yellow;
  }
  &.modal-text-white{
    color: $color-white;
  }
}
.modal-error-offset {
  min-width: $modal-error-offset-minWidth;
  height: $modal-error-offset-height;
  min-height: $modal-error-offset-minHeight;
}
.modal-success-offset {
  height: $modal-success-offset-height;
}

@media (min-width: 841px) and (max-width: 1199px) {
  .modal-wrapper {
    padding: $modal-wrapper-padding-tablet;
  }
}

@media screen and (max-width: 840px) {
  .main-modal {
    &.overlay {
      background-color: $auth-overlay-background-mobile;
    }
  }
  .modal-wrapper {
    max-width: $modal-wrapper-maxWidth-mobile;
    padding: $modal-wrapper-padding-tablet;
  }
  .modal-bought-subscription {
    grid-template-columns: $modal-bought-grid-template-mobile;
  }
  .modal-authBought-notify {
    padding: $modal-authBought-notify-padding-mobile;
  }
  .modal-title {
    font-size: $modal-title-size-mobile;
  }
  .modal-error-offset {
    height: $modal-error-offset-height-mobile;
  }
  .modal-success-offset {
    height: $modal-success-offset-height-mobile;
  }
}

@keyframes fade {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
@-moz-keyframes fade {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}