@import '../../assets/constants/colors';
@import '../../assets/mixins/ellipsis';
@import '../../assets/constants/convert_px.scss';
@import '../../assets/mixins/form_controllers.scss';

$auth-shell-grid-template: 1fr 1fr;
$auth-shell-grid-template-mobile: 1fr;

$auth-title-size: $s24;
$auth-title-size-mobile: $s22;

$auth-form-padding: 46px 60px 0;
$auth-form-padding-mobile: 46px 20px 0;
$auth-form-padding-tablet: 46px 26px 0;

$auth-textContent-margin-top: 18px;
$auth-textContent-lineHeigh: 1.1;
$auth-textContent-maxHeight: 52px;

$rules-label-lineHeigh: $auth-textContent-lineHeigh;
$form-redirect-link-size: $s16;

$rules-label-paddingLeft: 15px;
$rules-label-width: 89%;

$forgotten-password-form-padding: 0 60px;
$forgotten-password-form-padding-mobile: 0 20px;
$forgotten-password-form-padding-tablet: $forgotten-password-form-padding-mobile;

$login-form-row-position-mobile: 1;
$auth-contact-text-marginBottom: 35px;
$auth-submit-marginTop: 75px;

$auth-label-width: 92%;
$auth-label-width-mobile: 88%;

.modal-shell {
  &.auth-shell {
    display: grid;
    grid-template-columns: $auth-shell-grid-template;
  }
}
.auth-form {
  padding: $auth-form-padding;
}
.auth-title {
  font-size: $auth-title-size;
  text-align: center;
  &.text-login {
    color: $color-blue;
  }
  &.text-register {
    color: $color-white;
  }
  &.text-fp {
    color: $color-yellow;
  }
}
.auth-text-content {
  height: $auth-textContent-maxHeight;
  margin-top: $auth-textContent-margin-top;
  line-height: $auth-textContent-lineHeigh;

  text-align: center;
  &.text-login {
    color: $color-blue;
  }

  &.text-register {
    color: $color-white;
  }
  &.text-fp {
    color: $color-yellow;
  }
}
.auth-contact-text {
  color: $color-white;
  margin-bottom: $auth-contact-text-marginBottom;
}
.auth-label {
  width: $auth-label-width;
}
.rules-link {
  color: $color-yellow;
  text-decoration: underline;
}
.login-form {
  position: relative;
  background-color: $color-white;
}
.register-form,
.authorize-form,
.contact-form {
  position: relative;
  background-color: $color-blue;
}
.form-redirect-link {
  display: inline-block;
  text-decoration: underline;

  color: $color-yellow;
  font-size: $form-redirect-link-size;
}
.auth-submit-offset.field-set-btn {
  margin-top: $auth-submit-marginTop;
}

/* Forgotten Password */
.forgotten-password-form {
  padding: $forgotten-password-form-padding;
}

@media (min-width: 841px) and (max-width: 1199px) {
  .auth-form {
    padding: $auth-form-padding-tablet;
  }
  .forgotten-password-form {
    padding: $forgotten-password-form-padding-tablet;
  }
}

@media screen and (max-width: 840px) {
  .modal-shell {
    &.auth-shell {
      grid-template-columns: $auth-shell-grid-template-mobile;
    }
  }
  .auth-form {
    padding: $auth-form-padding-mobile;
  }
  .login-form {
    grid-row: $login-form-row-position-mobile;
  }
  .forgotten-password-form {
    padding: $forgotten-password-form-padding-mobile;
  }
  .auth-title {
    font-size: $auth-title-size-mobile;
  }
  .auth-label {
    width: $auth-label-width-mobile;
  }
}