@import '../../../../assets/constants/colors';
@import '../../../../assets/constants/convert_px';

$notify-tick-size: 5.37rem;
$notify-triangle-size: $s28;
$notify-title: 1.5rem;
$notify-title-offset: $s30;
$notify-messageHeading-width: 70%;
$notify-padding-offset: 10%;
$notify-email-offset: 2px;

$notify-button-size: 1.5rem;
$notify-close-button-top: 10px;
$notify-close-button-right: 20px;
$notify-info-lineHeigh: 1.3;
$notify-error-inline-icon-offset: 0 0 5px;

.notify-relative,
.notify-absolute,
.notify-error-inline {
  display: none;

  width: 100%;
  text-align: center;
  &.notify-blue {
    background-color: $color-blue;
    color: $color-white;
  }
  &.notify-white {
    background-color: $color-white;
    color: $color-blue;
  }
  &.active {
    display: block;
  }
  &.activeIndex {
    z-index: 3;
  }
  z-index: 1;
}
.notify-absolute {
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;

  .notify {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.notify-relative {
  position: relative;

  .notify {
    position: relative;

    .fa-exclamation-triangle,
    .fa-check,
    .fa-ban {
      margin-bottom: $s20;
    }
  }
}
.notify-wrapper-authorize {
  left: 0;
}
.notify {
  width: 100%;

  padding-left: $notify-padding-offset;
  padding-right: $notify-padding-offset;

  .fa-exclamation-triangle,
  .fa-check,
  .fa-ban {
    display: block;
    margin: 0;

    font-size: $notify-tick-size;
    color: $color-yellow;
  }
}
.notify-title {
  margin-bottom: $notify-title-offset;

  font-size: $notify-title;
  font-weight: 600;
}
.notify-body-error {
  font-size: $notify-title;
  font-weight: 600;
}
.notify-message-heading {
  display: inline-block;
  width: $notify-messageHeading-width;
}
.notify-heading-error {
  display: inline-block;
  width: 100%;
}
.notify-text-error {
  font-size: $notify-title;
  font-weight: 600;
}
.notify-email {
  padding-left: $notify-email-offset;
  color: $color-yellow;
  text-decoration: underline;
}
.notify-close {
  position: absolute;

  top: $notify-close-button-top;
  right: $notify-close-button-right;

  z-index: 2;
}
.notify-close-btn {
  font-size: $notify-button-size;
}
.notify-info {
  line-height: $notify-info-lineHeigh;
}
.notify-error-contact {
  margin: $s10 0;
  color: $color-yellow;
  font-weight: 600;
}
.notify-error-inline {
  text-align: center;
  margin: 0 auto;
  .fa-exclamation-triangle,
  .fa-check {
    display: block;
    margin: $notify-error-inline-icon-offset;

    font-size: $notify-triangle-size;
    color: $color-yellow;
  }
}
.error-offset {
  display: block;
  margin-top: $s10;
}
.notify-center {
  margin: 0 auto;
}
.notify-success-promocode {
  color: $color-yellow;
}
.payment-text {
  margin-top: 50px;
  font-size: $s18;

  a {
    color: $color-yellow;
    text-decoration: underline;
  }
}
@media screen and (max-width: 1200px) {
  .notify-relative {
    .notify {
      padding-bottom: $s20;
    }
  }
}