@import '../constants/colors.scss';
@import '../constants/convert_px.scss';
@import '../mixins/ellipsis.scss';

$form-input-height: 27px;
$form-input-padding: 0 15px 3px;
$form-input-delay: .30s;
$form-nput-answers-maxWidth: 100%;
$form-nput-answers-maxWidth-mobile: 100%;

$form-textarea-minHeight: 40px;
$form-textarea-height: 250px;
$form-textarea-padding: 10px;

$form-checkbox-width: 27px;
$form-checkbox-height: 27px;
$form-checked-tick-top: -6px;
$form-checked-tick-right: -7px;
$form-checked-tick-size: $s30;

$form-radio-width: 26px;
$form-radio-height: 26px;
$form-radio-checked-after-width: 18px;
$form-radio-checked-after-height: 18px;

$form-dropdown-width: 100%;
$form-dropdown-padding: 0 15px;
$form-dropdown-height: 27px;
$form-dropdown-bgRight-size: 9px 18px;
$form-dropdown-bgDown-size: 18px 9px;
$form-dropdown-size: $s18;
$form-dropdown-bg-position: 99% center;

$form-label-size: $s14;
$form-label-lineHeigh: 1.1;
$form-label-padding: 0 10px;
$form-label-error-bottom: -20px;
$form-label-error-top: -50px;
$form-label-focus-delay: .6s;

$form-label-answer-bottom: -35px;
$form-label-answer-height: 30px;
$form-field-set-margin: 35px 0;

$contact-field-set-margin: 35px 0 0;
$addSubscription-field-set-margin: 35px 0;
$field-set-btn-margin: 35px 0;
$invoice-field-set-margin: 35px 0 0;

$form-upload-label-margin: 20px 0;

/*
 * INPUT CONTROLLER
 */

.form-input {
  width: 100%;
  height: $form-input-height;

  padding: $form-input-padding;

  text-align: center;
  transition: all $form-input-delay ease-in-out;
  -webkit-transition: all $form-input-delay ease-in-out;
  -moz-transition: all $form-input-delay ease-in-out;
  -ms-transition: all $form-input-delay ease-in-out;

  box-shadow: none;

  &.input-blue {
    color: $color-blue;
    border-bottom: 1px solid $color-blue;
  }
  &.input-white {
    color: $color-white;
    border-bottom: 1px solid $color-white;
    background-color: $color-blue;
  }
  &.answers-size {
    max-width: $form-nput-answers-maxWidth;
  }
}
.form-input.input-blue,
.form-input.input-white {
  &.invalid {
    border-bottom: solid 1px $form-input-errorColor;
  }
}

/*
 * GLOBAL INHERETANCE
 */
.form-radio,
.form-checkbox,
.form-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-radio,
.form-checkbox {
  display: none;
}

/*
 * TEXTAREA CONTROLLER
 */
.form-textarea {
  display: block;
  resize: vertical;

  width: 100%;
  min-height: $form-textarea-minHeight;
  height: $form-textarea-height;
  padding: $form-textarea-padding;

  background: none;
  outline: none;

  &.textarea-white {
    color: $color-white;
    border: solid 1px $color-white;
  }
  &.textarea-blue {
    color: $color-blue;
    border: solid 1px $color-blue;
  }

  transition: all 0.30s ease-in-out;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;

  &:focus {
    border: solid 1px $auth-overlay-background-mobile;
    box-shadow: 0 0 5px $auth-overlay-background-mobile;
  }
}
.form-textarea {
  &.invalid {
    border: solid 1px $form-input-errorColor;
    box-shadow: 0 0 10px $form-input-errorColor;
    &:focus {
      border: solid 1px $form-input-errorColor;
      box-shadow: 0 0 10px $form-input-errorColor;
    }
  }
}
.textarea-clipboard {
  position: relative;

  height: 0;
  width: 0;
  opacity: 0;

  z-index: -1;
}
.clipboard-copy-iban {

  font-size: $s28;
  color: $color-blue;
  &.copy-success {
    color: $color-green;
    cursor: default;
  }
}

/*
 * DROPDOWN CONTROLLER
 */
.form-dropdown {
  width: $form-dropdown-width;
  height: $form-dropdown-height;
  padding: $form-dropdown-padding;

  border-bottom: solid 1px $color-blue;
  border-radius: 0;

  background-image: url('../../assets/img/chevron-right.svg');
  background-repeat:no-repeat;
  background-size: $form-dropdown-bgRight-size;
  background-position: $form-dropdown-bg-position;
  background-color: transparent transparent;

  font-size: $form-dropdown-size;
  color: $color-blue;

  text-align: center;
  text-align-last: center;

  -webkit-transition: background-size .3s ease-in;
  -moz-transition: background-size .3s ease-in;
  -ms-transition: background-size .3s ease-in;
  transition: background-size .3s ease-in;

  &.focus-bg {
    background-size: $form-dropdown-bgDown-size;
    background-image: url('../../assets/img/chevron-down.svg');
    font-size: inherit;
  }
  option {
    background-color: $color-white;
  }
}
.form-dropdown:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $color-black;
}

/*
 * CHECKBOX CONTROLLER
 */
.form-checkbox {
  &:checked + .form-checked-tick {
    .fa-check {
      visibility: visible;
    }
  }
}

.form-checked-tick {
  .fa-check {
    position: absolute;
    visibility: hidden;

    top: $form-checked-tick-top;
    right: $form-checked-tick-right;

    margin: 0;

    font-size: $form-checked-tick-size;
    color: $color-yellow;
  }
}

/*
 * RADIO BUTTON CONTROLLER
 */
.form-radio {
  &:checked + label {
    &:after {
      visibility: visible;
    }
  }
}

.form-radio-checked,
.form-checked-tick {
  position: relative;
  display: inline-block;

  vertical-align: middle;
  width: $form-radio-width;
  height: $form-radio-height;

  &.border-blue {
    border: solid 1px $color-blue;
  }
  &.border-white {
    border: solid 1px $color-white;
  }
}

.form-radio-checked {
  border-radius: 50%;
  &:after {
    position: absolute;
    visibility: hidden;

    content: '';

    top: 50%;
    left: 50%;

    width: $form-radio-checked-after-width;
    height: $form-radio-checked-after-height;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    background-color: $color-yellow;
  }
}

/*
 * UPLOAD FILE CONTROLLER
 */
.form-upload-file {
  display: none;
}
.form-upload-label {
  cursor: pointer;
  margin: $form-upload-label-margin;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: rgba(#344e5e, .5);
  }
}
/*
 * CONTROLLER FIELDSET
 */
.form-field-set {
  position: relative;
	border: none;

  &.field-set-btn {
    font-size: 0;
    text-align: center;
    margin: $field-set-btn-margin;
  }
  &.form-offset {
    margin: $form-field-set-margin;
  }
  &.contact-offset {
    margin: $contact-field-set-margin;
  }
  &.addSubscription-offset {
    margin: $addSubscription-field-set-margin;
  }
  &.invoice-offset {
    margin: $invoice-field-set-margin;
  }
  &.no-margin {
    margin: 0;
  }
  &.no-marginBottom {
    margin-bottom: 0;
  }
  &.no-marginTop {
    margin-top: 0;
  }
}

/*
 * CONTROLLER LABEL
 */
.form-label {
  display: inline-block;
  vertical-align: middle;

  width: 100%;
  padding: $form-label-padding;

	font-size: $form-label-size;
  line-height: $form-label-lineHeigh;

  &.label-blue {
    color: $color-blue;
  }
  &.label-white {
    color: $color-white;
  }
  &.form-label-answer {
    position: absolute;
    display: block;

    bottom: $form-label-answer-bottom;
    height: $form-label-answer-height;

    overflow: hidden;
    color: $color-light-blue;
    text-align: left;
  }
  &.form-label-error,
  &.form-label-error-single {
    position: absolute;
    display: block;

    color: $form-input-errorColor;
  }
  &.form-label-error {
    bottom: $form-label-error-bottom;
    @include ellipsis(100%, nowrap);
  }
  &.form-label-error-single {
    top: $form-label-error-top;
		width: 100%;
  }
}

.form-label-focus {
  position: absolute;
  bottom: 0;

  left: 50%;
  width: 0;

	transition: $form-label-focus-delay;
  -webkit-transition: $form-label-focus-delay;
  -moz-transition: $form-label-focus-delay;
	-ms-transition: $form-label-focus-delay;

  &.focus-animate {
    left: 0;
    width: 100%;
  }
  &.focus-blue {
    border-top: $color-white solid 2px;
    box-shadow: 0 0 1px $color-white;
  }
  &.focus-white {
    border-top: $color-focus-blue solid 2px;
    box-shadow: 0 0 1px $color-focus-blue;
  }
  &.invalid{
    border-color: $color-red;
    box-shadow: 0 0 1px $color-red;
  }
}

.form-label-required {
  position: absolute;
	right: 0;

  &.label-white {
    color: $color-white;
  }
  &.label-blue {
    color: $color-blue;
  }
}

.form-input.input-blue::-webkit-input-placeholder {
  color: $color-blue-placeholder;
}
.form-input.input-white::-webkit-input-placeholder {
  color: $color-white-placeholder;
}
.form-textarea::-webkit-input-placeholder {
  color: $color-blue-placeholder;
}

.form-input.input-blue:-ms-input-placeholder {
  color: $color-blue-placeholder;
}
.form-input.input-white:-ms-input-placeholder {
  color: $color-white-placeholder;
}
.form-textarea:-ms-input-placeholder {
  color: $color-white-placeholder;
}

.form-input.input-blue::placeholder {
  color: $color-blue-placeholder;
}
.form-input.input-white::placeholder {
  color: $color-white-placeholder;
}
.form-textarea::placeholder {
  color: $color-white-placeholder;
}

.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus {

  &.white-autofill {
    -webkit-text-fill-color: $color-blue;
    box-shadow: 0 0 0px 1000px $color-white inset;
    -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  &.blue-autofill {
    -webkit-text-fill-color: $color-white;
    box-shadow: 0 0 0px 1000px $color-blue inset;
    -webkit-box-shadow: 0 0 0px 1000px $color-blue inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
@media screen and (max-width: 840px) {
  .form-input {
    &.answers-size {
      max-width: $form-nput-answers-maxWidth-mobile;
    }
  }
}