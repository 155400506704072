@import "./assets/mixins/buttons.scss";
@import "./assets/constants/colors.scss";

$body-font-stack: 'Ubuntu', sans-serif !default;
$form-controllers-fontSize: 1.1rem !default;
$global-default-size: 16px !default;
$font-size: 1rem !default;

$body-line-height: 1;
$body-min-height: 100vh;
$button-y-padding: 10px;
$page-shell-minHeight: 450px;

/* Basic Resets
  **********************************************************************
*/
html {
  font-size: $global-default-size;
}
body {
  margin: 0;

  font-family: $body-font-stack;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: $global-black-reset;
  box-sizing: border-box;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* HTML Elements
  **********************************************************************
*/
body {
  min-height: $body-min-height;

  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: $body-line-height;
}

html,
body,
#root,
.main-wrap {
  height: 100%;
}

a {
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
  -webkit-tap-highlight-color: $global-black-reset;

  text-decoration: none;
  outline: 0;

  &:hover {
  -webkit-tap-highlight-color: $global-black-reset;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
  }

  &:active,
  &:hover {
    outline: none;
  }

  img {
    border: none;
  }
}

ul, p {
  margin: 0;
  padding: 0;

}
ul {
  list-style: none;
}

img {
  vertical-align: middle;
  border: 0;
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}
.clearfix {
	@include clearfix;
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

/* HTML display definitions
  **********************************************************************
*/

article,
aside,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section{
  display: block;
}

/* Form Elements
  **********************************************************************
*/

button,
input,
select,
textarea {
  font: inherit;
  font-size: $form-controllers-fontSize;

  border: none;
  margin: 0;

  &::-webkit-input-placeholder {
    font-size: $form-controllers-fontSize;
  }

  &:-ms-input-placeholder {
    font-size: $form-controllers-fontSize;
  }

  &::placeholder {
    font-size: $form-controllers-fontSize;
  }
}

textarea,
input,
button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

textarea {
  overflow: auto;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}


button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.img {
  display: block;
  width: 100%;
}

button {
  border: none;
  background: none;
}
fieldset  {
  margin: 0;
  padding: 0;
}
// helpers
.y-axis-margin {
  margin: 15px 0;
}

.remove-top-margin {
  margin-top: 0
}

.text-center {
  text-align: center;
}

.visible-mobile {
  display: block !important;
}

.visible-desktop {
  display: none !important;
}

@media screen and (min-width: 1200px) {
  .visible-mobile {
    display: none !important;
  }

  .visible-desktop {
    display: block !important;
  }
}

.display-flex {
  display: flex;

  &.text-center {
    justify-content: center;
  }
}

.wrapper {
  h1,
  .main-title {
    font-size: 1.4rem;
    text-align: center;

    @media screen and (min-width: 1200px) {
      font-size: 1.8rem;
    }
  }

  .text-content {
    position: relative;
    @media screen and (max-width: 1199px) {
      text-align: center;
    }

    p,
    .parsed-html {
      margin: 20px 0;
      font-size: 1rem;
      text-align: center;
      line-height: 1.2;

      @media screen and (min-width: 1200px) {
        font-size: 1.3rem;
        text-align: left;
      }


      img {
        display: block;
        float: left;
        margin: 30px 10px;

        @media screen and (max-width: 1199px) {
          width: 100%;
        }
      }
    }

  }
  .collapsible-content {
    p {
      position: relative;
      display: block;
      padding: 0 0 80px 0;
      height: 300px;
      overflow: hidden;

      &.active {
        height: 100%;
      }
    }
    b {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      text-align: center;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.9) 10%, rgba(255,255,255,1) 100%);
      padding: 20px 0 0 0;
    }
  }
}

section.wrapper {
  a[title~="button-b"],
  a[title~="button-y"] {
    display: inline-block;
    text-decoration: none;

    transition: all .25s ease-in;
    padding: $button-y-padding;

    text-align: center;
    vertical-align: middle;

    font-weight: 600;
    }
}
section.wrapper {
  a[title~="button-y"] {
    background-color:$color-yellow;
    color: $color-blue;
    &:hover{
      background-color: $color-blue;
      color: $color-white;
    }
  }
}
section.wrapper {
  a[title~="button-b"] {
    background-color:$color-blue;
    color: $color-white;
    &:hover{
      background-color: $color-yellow;
      color: $color-blue;
    }
  }
}

/* Page shell - Global */
.page-shell {
  min-height: $page-shell-minHeight;
}

/* Paginator pages counter text */
.pages-counter {
  margin-top: 10px;
  text-align: center;
  font-size: $s18;
  color: $color-blue;
}